import type { MenuItem } from "../../MenuItem/MenuItem";

import styles from "./AboutOmniGroup.module.scss";
import { triggerTCFSettingsModal } from "../../../../../tcf/helpers";
import { MenuGroup } from "../MenuGroup";
import { getBrandedAboutOmniLinks } from "./utils/getBrandedAboutOmniLinks";

type AboutOmniItemsGroupItem = {
  id: string;
  title: string;
  items: MenuItem[];
};

const AboutOmniGroup = () => {
  const links = getBrandedAboutOmniLinks();
  const aboutOmniItems = [
    {
      id: "omOmni",
      title: "Om Omni",
      items: [
        {
          text: "Tyck till om Omni",
          href: links.contact,
          isExternalLink: true,
          type: "link",
        },
        {
          text: "Hjälp & kontakt",
          href: links.customerSupport,
          isExternalLink: true,
          type: "link",
        },
        {
          text: "Ansvarig utgivare",
          href: links.publisher,
          isExternalLink: true,
          type: "link",
        },
        {
          text: "Utgivarbloggen",
          href: links.utgivarBloggen,
          isExternalLink: true,
          type: "link",
        },
      ],
    },
    {
      id: "dinPersonligaData",
      title: "Din personliga data",
      items: [
        {
          text: "Personuppgiftspolicy",
          showSchibstedLogo: true,
          href: links.privacyPolicy,
          isExternalLink: true,
          type: "link",
        },
        {
          text: "Sekretessinställningar",
          showSchibstedLogo: true,
          href: links.confidentialitySettings,
          isExternalLink: true,
          type: "link",
        },
        {
          text: "Inställningar för cookies",
          showSchibstedLogo: true,
          type: "button",
          onClick: triggerTCFSettingsModal,
        },
      ],
    },
  ] as AboutOmniItemsGroupItem[];

  return (
    <div className={styles.aboutOmniContainer}>
      {aboutOmniItems.map((item) => (
        <MenuGroup
          key={item.id}
          title={item.title}
          items={item.items}
          columnWidth={1}
        />
      ))}
    </div>
  );
};

export { AboutOmniGroup };
