import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./useModal.module.scss";

type ModalWrapperProps = {
  children: React.ReactNode;
};

type UseModal = () => {
  open: (event: React.UIEvent) => void;
  close: (event: React.UIEvent | UIEvent) => void;
  ModalWrapper: React.FC<ModalWrapperProps>;
  ref: React.RefObject<HTMLElement>;
  isOpen: boolean;
};

const useModal: UseModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLElement>(null);

  const open = useCallback((event: React.UIEvent) => {
    event.stopPropagation();
    setIsOpen(true);
  }, []);

  const close = useCallback((event: React.UIEvent | UIEvent | MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleKeyOutside = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        close(event);
      }
    };

    if (isOpen) {
      ref?.current?.focus();
      document.addEventListener("keydown", handleKeyOutside);
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.removeEventListener("keydown", handleKeyOutside);
      document.body.classList.remove("no-scroll");
    };
  }, [ref, isOpen, close]);

  const ModalWrapper: React.FC<ModalWrapperProps> = ({ children }) => {
    const handleClickOutside = (event: React.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        close(event);
      }
    };

    return createPortal(
      <div id="generalModal" className="modal">
        <div className={styles.background} onClick={handleClickOutside}>
          {children}
        </div>
      </div>,
      document.getElementById("general-modal-container")!,
    );
  };

  return {
    ModalWrapper,
    isOpen,
    ref,
    open,
    close,
  };
};

export { useModal };
